import { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import countryOptions from '../components/Countries';

const PartnersModal = () => {

    const [selectedCountry, setselectedCountry] = useState(countryOptions[0]);
    const { t } = useTranslation();

    const data = {
        firstName: "",
        lastName: "",
        email: ""
    }

    const [inputData, setInputData] = useState(data);

    function handlerData(e) {
        setInputData({...inputData, [e.target.name]:e.target.value});       
    }



    const handleSubmit=async(e)=> {
        e.preventDefault();
        if(!inputData.firstName || !inputData.lastName || !inputData.email||!selectedCountry.value) {
            alert('All field are mandatory');
        } else {
            try {
                const response = await fetch(`https://app.loamoa-dev-merge.cladev.com//api/armax/partnerRequest/new`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    
                  },
                  body: JSON.stringify({
                 first_name:inputData.firstName,
                 last_name:inputData.lastName,
                  email:inputData.email,
                  countrySelect:selectedCountry.value
                  }),
                });
          
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
          
                // Handle successful response
                  await response.json();
                  alert("Form submit successfully")
                  
              } catch (error) {
                alert('Error during POST request:', error.message);
              } 
        }
    }       
 

    return (
        <div className="modal fade" id="partnersProgramModal" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-md modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className='section-header mt-30'>
                            <h3>{t('AboutLoaMoaPartners')}</h3>
                            <p className='size14'>{t('informationLoaMoaPartners')}</p>
                        </div>

                        <div className='support-modal'>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <input type="text" name='firstName' value={inputData.firstName} className="form-control" placeholder={t('First Name')} onChange={handlerData} />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <input type="text" name='lastName' value={inputData.lastName} className="form-control" placeholder={t('Last Name')} onChange={handlerData} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <Select
                                            className='custom-react-select size14'
                                            classNamePrefix='custom-react-select'
                                            defaultValue={selectedCountry}
                                            onChange={setselectedCountry}
                                            options={countryOptions}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <input type="email" name='email' value={inputData.email} className="form-control" placeholder={t('Email')} onChange={handlerData} />
                                    </div>
                                </div>

                                <div className='text-center mt-15'>
                                    <button type='submit' data-bs-dismiss="modal" className='btn btn-primary btn-long font-bold' onClick={handleSubmit}>{t('Submit')}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PartnersModal;
