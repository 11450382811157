export const FileUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      console.log("file",file)
      const fetchResponse =  await fetch(`https://app.loamoa-dev-merge.cladev.com/api/v2/file/upload`,{
        method: 'POST',
        headers: {
          //'access-token': store.getState().authentication.user.newToken,
          'Accept': 'application/json',
        },
        body: formData
      });
      const response =   await fetchResponse.json();
      return response.data
    //   if (response.code === responseCodes.successful) {
    //     return response.data;
    //   } else {
    //      // showErrorMessage(response.error, response.code);
    //     return Promise.reject('failed to get sections');
    //   }
    } catch (error) {
      //showErrorMessage("Try after sometimes");
      return error;
    }
  };