import { useState } from 'react';
//import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { FileUpload } from './servicesapi';
import Langi18next from './LanguageTranslate/Langi18next';





const RequestModal = () => {
    const { t } = useTranslation();
    const data = {
        email: "",
        username: "",
        subject: "",
        description: "",
       // fileUpload: "",
        myselect:""
    }
  // const [selectedOption, setSelectedOption] = useState(null);
    const [inputData, setInputData] = useState(data);
    const [selectedImage, setFirstFileName] = useState(null);
    const [firstFile, setFirstFile] = useState(null);

    // const options = [
    //     { value: 'english', label: 'English' },
    //     { value: 'korean', label: '한국어' },
    // ];

    const handlerData=(e)=> {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
    }

    const handleImageChange = async (e) => {
        
        const selectedImage = e.target.files[0];
        const selectedFileName = selectedImage.name;
        const result = await FileUpload(selectedImage);
        const selectedFile = result.file;
            setFirstFileName(selectedFileName);
            setFirstFile(selectedFile);
    };
   
    const handleSubmit=async(e)=> {
        e.preventDefault();
        if(!inputData.username || !inputData.subject || !inputData.email||!inputData.myselect||!inputData.description||!firstFile) {
            alert('All field are mandatory');
        } else {
            try {
                const response = await fetch(`https://app.loamoa-dev-merge.cladev.com/api/armax/partnerRequest/support_request`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',   
                  },
                  body: JSON.stringify({
                 username:inputData.username,
                 subject:inputData.subject,
                  email:inputData.email,
                  support_type:inputData.myselect,
                  description:inputData.description,
                  attachement:firstFile
                  }),
                });
          
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
          
                // Handle successful response
                  await response.json();
                  alert("Form submit successfully")
                  
              } catch (error) {
                alert('Error during POST request:', error.message);
              } 
        }
    }  
    
    return (
        <div className="modal fade" id="supportModal" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"></button>
                        <div className='section-header mt-30'>
                            <h3>{t('Submit a Request')}</h3>
                        </div>

                        <div className='support-modal'>
                            <div className="fx-jcfe">
                                <div className="mb-3 lang-select-block">
                                    <Langi18next/>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label">{t('How can we help you?')}<sup>*</sup></label>
                                <select className='form-select' id="mySelect" name='myselect' value={inputData.myselect} onChange={handlerData}>
                                    <option value="">Select</option>
                                    <option value="Account & Master account">{t('Account & Master account')}</option>
                                    <option value="Dome Address & Dome">{t('Dome Address & Dome')}</option>
                                    <option value="Flash, Adskit, Green, ARshow">{t('Flash, Adskit, Green, ARshow')}</option>
                                    <option value="Lcash, points, plum">{t('Lcash, points, plum')}</option>
                                    <option value="Payment & billing">{t('Payment & billing')}</option>
                                    <option value="App (iOS, Android">{t('App (iOS, Android)')}</option>
                                    <option value="Other">{t('Other')}</option>
                                </select>

                            </div>
                            <div className="mb-3">
                                <label htmlFor="req_email" className="form-label">{t('Your email address')}<sup>*</sup></label>
                                <input name='email' value={inputData.email} type="email" onChange={handlerData} id='req_email' className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="req_username" className="form-label">{t('LoaMoa username(Nickname)')}<sup>*</sup></label>
                                <input type="text" value={inputData.username} name='username' onChange={handlerData} id='req_username' className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="req_subject" className="form-label">{t('Subject')}<sup>*</sup></label>
                                <input type="text" value={inputData.subject} onChange={handlerData} name='subject' id='req_subject' className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="req_description" className="form-label">{t('Description')}<sup>*</sup></label>
                                <textarea rows="4" value={inputData.description} onChange={handlerData} name='description' id='req_description' className='form-control' placeholder='Please describe the inquires in specific details'></textarea>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="req_fileUploadInp" className="form-label">{t('Attachments')}</label>
                                <div className='position-relative fileUploadArea'>
                                    <span>{selectedImage ? selectedImage:'File name'}</span>
                                    {/* <input type="text"  name='attachment' id='req_fileUploadInp' className="form-control" /> */}
                                    <label htmlFor="req_fileUpload" className='fileUpload'>

                                        <input type="file"  onChange={handleImageChange} name="fileUpload" id="req_fileUpload" />
                                        {t('Add File')}
                                    </label>
                                </div>
                            </div>
                            <div className='text-center mt-15'>
                                <button type='submit'onClick={handleSubmit} className='btn btn-primary btn-long font-bold'>{t('Submit')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestModal
