import { useEffect } from 'react';
import TermsofUseContent from '../components/TermsofUseContent';


const TermsofUse = () => {
    useEffect(() => {
        document.title = 'LoaMoa || Terms of Use';
        window.scrollTo(0, 0);
    }, []);

  return (
    <>
        <TermsofUseContent />
    </>
  )
}

export default TermsofUse
