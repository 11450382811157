import React, { useState, useEffect } from 'react';
import { Link, NavLink  } from 'react-router-dom';
import RequestModal from './RequestModal';
import PartnersModal from './PartnersModal';
import { useTranslation } from 'react-i18next';
// import { useEffect } from 'react';
import { NavHashLink } from 'react-router-hash-link';

export default function Footer() {
    const { t } = useTranslation();
    const [scrollToTop, setScrollToTop] = useState(false);

    let localLang = localStorage.getItem('lang');
    let androidUrl = `https://play.google.com/store/apps/details?id=com.loamoa&hl=${localLang}_BO&pli=1`
    
    
    useEffect   (() => {
        if (scrollToTop) {
          // Scroll to the top of the component
          window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: for smooth scrolling
          });
    
          // Reset the state to prevent continuous scrolling
          setScrollToTop(false);
        }
      }, [scrollToTop]);

    return (
        <>
            <footer className="footer-main">
                <div className="container px-4">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-5">
                                    <figure>
                                        <img src="images/loamoa-logo.svg" alt="LoaMoa" height={34} />
                                    </figure>
                                    <div className="getInTouch">
                                        <h5>{t('Get in Touch')}</h5>
                                        <div className="footer-socialLinks">
                                            <ul>
                                                <li><Link to="https://www.facebook.com/LoaMoa-105679681294012"><img src="images/facebook-icon.svg" alt="" /></Link></li>
                                                <li><Link to="https://www.instagram.com/loamoa_official/"><img src="images/instagram-icon.svg" alt="" /></Link></li>
                                                <li><Link to="https://www.youtube.com/channel/UC1NsT-PiME3C34Gr9K-Iu2Q"><img src="images/youtube-icon.svg" alt="" /></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col footer-col">
                                    <h4>{t('COMPANY')}</h4>
                                    <ul className="footerLinks">
                                        <li><NavLink to="/about">{t('ABout')}</NavLink></li>
                                        <li><NavLink to="/careers">{t('Careers')}</NavLink></li>
                                        <li><NavHashLink to='/features#partnerSection'>{t('Partners')}</NavHashLink></li>
                                        <li><NavLink to="/privacy-policy">{t('Privacy Policy')}</NavLink></li>
                                        <li><NavLink to="/terms-of-use">{t('Terms of Use')}</NavLink></li>
                                    </ul>
                                </div>
                                <div className="col footer-col">
                                    <h4>LOAMOA</h4>
                                    <ul className="footerLinks">
                                        <li><NavLink to="/dome" onClick={()=>setScrollToTop(true)}>{t('Dome')}</NavLink></li>
                                        <li><NavLink to="/features" onClick={()=>setScrollToTop(true)}>{t('Features')}</NavLink></li>
                                        <li><NavLink to="/faq" onClick={()=>setScrollToTop(true)}>{t('Support')}</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 copyr">
                                    <span>ⓒ Armax Inc. All rights reserved.</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mtm-15">
                            <div className="row align-items-center">
                                <div className="col-md-5 app-download-block">
                                    <h4>{t('Download App')}</h4>
                                    <div className="app-download-links">
                                        <Link target='_blank' to="https://apps.apple.com/tc/app/loamoa/id1500906138"><img src="images/app-store-btn-long.svg" alt="App Store" /></Link>
                                        <Link target='_blank' to={androidUrl}><img src="images/play-store-btn-long.svg" alt="App Store" /></Link>
                                    </div>
                                </div>
                                <div className="col-md-7 footer-mobile-fig">
                                    <img src="images/footer-mobiles.png" alt="mobile" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <RequestModal />
            <PartnersModal />
            
        </>
    )
}
