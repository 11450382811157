import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
const About = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = 'LoaMoa || About';
        window.scrollTo(0, 0);
    }, []);

  return (
    <>
        <section className='content-page-banner'>
            <div className="container">
                <h2>Armax Inc.</h2>
                <p>{t('aboutpera')}</p>
            </div>
        </section>

        <section className='mission-sec'>
            <div className="container px-4">
                <div className="row gx-5 align-items-center">
                    <div className="col-md-6 mission-sec__left">
                        <img src="images/mission-art.jpg" alt="Mission" />
                    </div>
                    <div className="col-md-6 mission-sec__right">
                        <div className='section-header leftAlign'>
                            <h2>{t('Mission')}</h2>
                            <p>{t('mission_about')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default About
