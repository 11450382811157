// import { Link } from 'react-router-dom';
import HeroBanner from '../components/HeroBanner';
import { useEffect } from 'react';
import FeaturesCard from '../components/FeaturesCard';

import featuresData from '../components/featuresData';
import featuresDatako from '../components/featuresDataKo'
import { useTranslation } from 'react-i18next';
import { NavHashLink } from 'react-router-hash-link';

function Home() {
    const { t } = useTranslation();


    useEffect(() => {
        document.title = 'LoaMoa || Home';
        window.scrollTo(0, 0);
    }, []);



    return (
        <>

            <HeroBanner />

            <section className="section about-section">
                <div className="container px-4">
                    <div className="section-header">
                        <h2><strong className='font-secondary'>LoaMoa</strong></h2>
                        <h5 className='font-bold'>{t('CHILLIN EVERYDAY')}</h5>
                        <p>{t('fun')}</p>
                    </div>
                </div>
            </section>
            {/* About Section */}

            <section className="section arsho-section">
                <div className="arsho-section__left">
                    <img className="arsho-mobile-fig" src="images/arshow-mobile.png" alt="mobile" />
                </div>
                <div className="arsho-section__right">
                    <div className="section-header leftAlign">
                        <h2>{t('ARshow')}</h2>
                        <p>{t('turn')}
                        </p>
                        <NavHashLink to="/dome#arshowSection" className="btn btn-primary">{t('View More')}</NavHashLink>
                    </div>
                </div>
            </section>
            {/* Arshow */}

            <section className="arsho-section-2">
                <div className="container px-4">
                    <div className="arsho-section-2__inside">
                        <div className="section-header leftAlign">
                            <h2>{t('ARshow')}</h2>
                            <p>{t('Famous')}
                            </p> 
                            <NavHashLink to="/dome#arshowSection" className="btn btn-primary">{t('View More')}</NavHashLink>
                        </div>
                    </div>
                </div>
            </section>
            {/* Arshow 2 */}

            <section className="features-section">
                <div className="container px-4">
                    <div className="row">
                        <div className="col-md-8 features-section__left">
                            <div className="features-cards-row">
                                {
                                localStorage.getItem('lang') === 'ko' ? featuresDatako.map((items, i) => {
                                    return <FeaturesCard key={i} items={items} />
                                }) : featuresData.map((items, i) => {
                                    return <FeaturesCard key={i} items={items} />
                                })
                               }

                            </div>
                        </div>
                        <div className="col-md-4 features-section__right">
                            <div className="section-header leftAlign pt-40">
                                <h2>{t('Features')}</h2>
                                <p>{t('Experience')}
                                </p>
                                <NavHashLink to="/features#featuresPage" className="btn btn-primary">{t('View More')}</NavHashLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home;