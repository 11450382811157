import { useEffect } from 'react';
import PrivacyPolicyContent from '../components/PrivacyPolicyContent';

const PrivacyPolicyApp = () => {
    
    useEffect(() => {
        document.title = 'LoaMoa || Privacy Policy';
        window.scrollTo(0, 0);

        let mainHeader = document.querySelector(".mainHeader")
        let footermain = document.querySelector(".footer-main")
        let innerBanner = document.querySelector(".content-page-banner")
        mainHeader.style.display = 'none';
        footermain.style.display = 'none';
        innerBanner.style.paddingTop = '25px';
        innerBanner.style.minHeight = '100px';
    }, []);

    

  return (
    <>
        <PrivacyPolicyContent />
    </>
  )
}

export default PrivacyPolicyApp
