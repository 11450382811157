import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

const Faq = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = 'LoaMoa || FAQs';
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className='content-page-banner smaller'>
                <div className="container">
                    <button className="btn btn-white font-bold" data-bs-toggle="modal" data-bs-target="#supportModal">{t('Submit a Request')}</button>
                </div>
            </section>

            <section className="section">
                <div className="container px-4">
                    <div className="section-header">
                        <h2>FAQs</h2>
                    </div>

                    <div className="faq-card">
                        <ul className="nav nav-tabs default" id="myTab" role="tablist">
                            <li className="nav-item">
                                <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#tab-1" type="button">{t('Account')}</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2" type="button">{t('Dome Address')}</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3" type="button">{t('Dome')}</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4" type="button">{t('ARshow')}</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-5" type="button">{t('flash')}</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-6" type="button">{t('Adskit')}</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-7" type="button">{t('Sticker')}</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-8" type="button">{t('Candy')}</button>
                            </li>
                            <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-9" type="button">{t('Green')}</button>
                            </li>
                        </ul>

                        <div className="tab-content" id="accordionFaq">
                            <div className="tab-pane fade show active" id="tab-1" role="tabpanel" tabIndex="0">
                                <div className="accordion faqStyle">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#account_1">
                                            {t('how_to_sign')}
                                            </button>
                                        </h2>
                                        <div id="account_1" className="accordion-collapse collapse show" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('how_to_sign_ans')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#account_2">
                                            {t('service_composed')}
                                            </button>
                                        </h2>
                                        <div id="account_2" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('service_composed_ans')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#account_3">
                                            {t('Who_can_change_master')}
                                            </button>
                                        </h2>
                                        <div id="account_3" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('Who_can_change_master_ans')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#account_4">
                                            {t('changetoMasterAccount')}
                                            </button>
                                        </h2>
                                        <div id="account_4" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('changetoMasterAccountAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#account_5">
                                            {t('Wheretoupdate')}
                                            </button>
                                        </h2>
                                        <div id="account_5" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WheretoupdateAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#account_6">
                                            {t('Whichbrowseroptimized')}
                                            </button>
                                        </h2>
                                        <div id="account_6" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WhichbrowseroptimizedAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#account_7">
                                            {t('CanIwatchvideo')}
                                            </button>
                                        </h2>
                                        <div id="account_7" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('CanIwatchvideoAns')} <Link className="goToVideo">Go to Video</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="tab-2" role="tabpanel" tabIndex="0">
                                <div className="accordion faqStyle">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_address_1">
                                            {t('Howtoregister')}
                                            </button>
                                        </h2>
                                        <div id="dome_address_1" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('HowtoregisterAns')}</p>
                                                <ul>
                                                    <li>{t('HowtoregisterAnsA')}</li>
                                                    <li>{t('HowtoregisterAnsB')}</li>
                                                    <li>{t('HowtoregisterAnsC')}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_address_2">
                                                {t('Wheredoyouregister')}
                                            </button>
                                        </h2>
                                        <div id="dome_address_2" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WheredoyouregisterAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_address_3">
                                            {t('DifferenceBetweenDome')}
                                            </button>
                                        </h2>
                                        <div id="dome_address_3" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('DifferenceBetweenDomeAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_address_4">
                                            {t('AdvantagesofDomeAddress')}
                                            </button>
                                        </h2>
                                        <div id="dome_address_4" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('AdvantagesofDomeAddressAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_address_5">
                                            {t('purchaseDomeAddress')}
                                            </button>
                                        </h2>
                                        <div id="dome_address_5" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <ul>
                                                    <li> {t('purchaseDomeAddressAnsA')}</li>
                                                    <li>{t('purchaseDomeAddressAnsB')}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_address_6">
                                            {t('checkpurchasedDome')}
                                            </button>
                                        </h2>
                                        <div id="dome_address_6" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('checkpurchasedDomeAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_address_7">
                                                {t('RegisterDomeAddress')}
                                            </button>
                                        </h2>
                                        <div id="dome_address_7" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('RegisterDomeAddressAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_address_8">
                                            {t('CanRegisteredDomeAddresses')}
                                            </button>
                                        </h2>
                                        <div id="dome_address_8" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('CanRegisteredDomeAddressesAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_address_9">
                                            {t('CanIwatchVideoExample')}
                                            </button>
                                        </h2>
                                        <div id="dome_address_9" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('CanIwatchVideoExampleAns')} <Link class="goToVideo" to="">Go to Video</Link></p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>




                            <div className="tab-pane fade" id="tab-3" role="tabpanel" tabIndex="0">
                                <div className="accordion faqStyle">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_1">
                                            {t('HowtoBuildUtilize')}
                                            </button>
                                        </h2>
                                        <div id="dome_1" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('HowtoBuildUtilizeAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_2">
                                            {t('DomeCurrentlyAlpha')}
                                            </button>
                                        </h2>
                                        <div id="dome_2" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('DomeCurrentlyAlphaAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_3">
                                            {t('CanIexpectRevenue')}
                                            </button>
                                        </h2>
                                        <div id="dome_3" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('CanIexpectRevenueAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_4">
                                            {t('AreDomeandArena')}
                                            </button>
                                        </h2>
                                        <div id="dome_4" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('AreDomeandArenaAns')}</p>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_4">
                                            {t('WhatistheProcedure')}
                                            </button>
                                        </h2>
                                        <div id="dome_4" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WhatistheProcedureAns')}</p>
                                                <ul>
                                                    <li>{t('WhatistheProcedureAnsA')}</li>
                                                    <li>{t('WhatistheProcedureAnsB')}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_5">
                                            {t('WhatisDomeName')}
                                            </button>
                                        </h2>
                                        <div id="dome_5" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WhatisDomeNameAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_6">
                                            {t('DomeNameinweb')}
                                            </button>
                                        </h2>
                                        <div id="dome_6" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('DomeNameinwebAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_7">
                                            {t('HowtoSelectTemplate')}
                                            </button>
                                        </h2>
                                        <div id="dome_7" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('HowtoSelectTemplateAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_8">
                                            {t('Howtoturnthestatus')}
                                            </button>
                                        </h2>
                                        <div id="dome_8" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('HowtoturnthestatusAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_9">
                                                {t('CanIwatchanExample')}
                                            </button>
                                        </h2>
                                        <div id="dome_9" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('CanIwatchanExampleAns')} <Link class="goToVideo" to="">Go to Video</Link></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_10">
                                            {t('publishDometheWeb')}
                                            </button>
                                        </h2>
                                        <div id="dome_10" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('publishDometheWebAns')} <Link class="goToVideo" to="">Go to Video</Link></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_11">
                                            {t('Whatispossible')}
                                            </button>
                                        </h2>
                                        <div id="dome_11" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WhatispossibleAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#dome_12">
                                            {t('contentvideoofArena')}
                                            </button>
                                        </h2>
                                        <div id="dome_12" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('contentvideoofArenaAns')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="tab-pane fade" id="tab-4" role="tabpanel" tabIndex="0">
                                <div className="accordion faqStyle">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#arshow_1">
                                            {t('IcanpostanARshow')}
                                            </button>
                                        </h2>
                                        <div id="arshow_1" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('IcanpostanARshowAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#arshow_2">
                                            {t('IspostinganARshow')}
                                            </button>
                                        </h2>
                                        <div id="arshow_2" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('IspostinganARshowAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#arshow_3">
                                            {t('HowdoIpostARshow')}
                                            </button>
                                        </h2>
                                        <div id="arshow_3" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('HowdoIpostARshowAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#arshow_4">
                                            {t('HowdoIfindARshow')}
                                            </button>
                                        </h2>
                                        <div id="arshow_4" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('HowdoIfindARshowAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#arshow_5">
                                            {t('Isitnecessary')}
                                            </button>
                                        </h2>
                                        <div id="arshow_5" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('IsitnecessaryAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#arshow_6">
                                            {t('profitbyposting')}
                                            </button>
                                        </h2>
                                        <div id="arshow_6" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('profitbypostingAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#arshow_7">
                                            {t('Plumsdonated')}
                                            </button>
                                        </h2>
                                        <div id="arshow_7" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('PlumsdonatedAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#arshow_8">
                                            {t('CanItransfer')}
                                            </button>
                                        </h2>
                                        <div id="arshow_8" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('CanItransferAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#arshow_9">
                                            {t('WhattypefileARshow')}
                                            </button>
                                        </h2>
                                        <div id="arshow_9" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WhattypefileARshowAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#arshow_10">
                                            {t('examplevideoofARshow')}
                                            </button>
                                        </h2>
                                        <div id="arshow_10" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('examplevideoofARshowAns')} <Link class="goToVideo" to="">Go to Video</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="tab-pane fade" id="tab-5" role="tabpanel" tabIndex="0">
                                <div className="accordion faqStyle">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flash_1">
                                            {t('Whathappenswhen')}
                                            </button>
                                        </h2>
                                        <div id="flash_1" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WhathappenswhenAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flash_2">
                                            {t('userstaptheFlashicon')}
                                            </button>
                                        </h2>
                                        <div id="flash_2" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('userstaptheFlashiconAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flash_3">
                                            {t('cashpointsyouearn')}
                                            </button>
                                        </h2>
                                        <div id="flash_3" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('cashpointsyouearnAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flash_4">
                                            {t('claimanofflineprize')}
                                            </button>
                                        </h2>
                                        <div id="flash_4" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('claimanofflineprizeAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flash_5">
                                            {t('distributeAFlash')}
                                            </button>
                                        </h2>
                                        <div id="flash_5" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('distributeAFlashAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flash_6">
                                            {t('AdditionalPrizes')}
                                            </button>
                                        </h2>
                                        <div id="flash_6" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('AdditionalPrizesAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flash_7">
                                            {t('examplevideodistribute')}
                                            </button>
                                        </h2>
                                        <div id="flash_7" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('examplevideodistributeAns')} <Link class="goToVideo" to="">Go to Video</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="tab-pane fade" id="tab-6" role="tabpanel" tabIndex="0">
                                <div className="accordion faqStyle">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#adskit_1">
                                            {t('WhatisAdskit')}
                                            </button>
                                        </h2>
                                        <div id="adskit_1" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WhatisAdskitAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#adskit_2">
                                            {t('usersearncashpoints')}
                                            </button>
                                        </h2>
                                        <div id="adskit_2" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('usersearncashpointsAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#adskit_3">
                                            {t('differencesbetweenAdskit')}
                                            </button>
                                        </h2>
                                        <div id="adskit_3" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('differencesbetweenAdskitAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#adskit_4">
                                            {t('usethepoints')}
                                            </button>
                                        </h2>
                                        <div id="adskit_4" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('usethepointsAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#adskit_5">
                                            {t('WhatisSubscribe')}
                                            </button>
                                        </h2>
                                        <div id="adskit_5" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WhatisSubscribeAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#adskit_6">
                                            {t('viewbrandsproducts')}
                                            </button>
                                        </h2>
                                        <div id="adskit_6" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('viewbrandsproductsAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#adskit_7">
                                            {t('distributeAdskit')}
                                            </button>
                                        </h2>
                                        <div id="adskit_7" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('distributeAdskitAns')} <Link class="goToVideo" to="">Go to Video</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="tab-pane fade" id="tab-7" role="tabpanel" tabIndex="0">
                                <div className="accordion faqStyle">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sticker_1">
                                            {t('Whatarestickers')}
                                            </button>
                                        </h2>
                                        <div id="sticker_1" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WhatarestickersAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sticker_2">
                                            {t('HowUsestickers')}
                                            </button>
                                        </h2>
                                        <div id="sticker_2" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('HowUsestickersAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sticker_3">
                                            {t('Howtousestickers')}
                                            </button>
                                        </h2>
                                        <div id="sticker_3" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('HowtousestickersAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sticker_4">
                                            {t('textserviceavailable')}
                                            </button>
                                        </h2>
                                        <div id="sticker_4" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('textserviceavailableAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sticker_5">
                                            {t('exampleusestickers')}
                                            </button>
                                        </h2>
                                        <div id="sticker_5" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('exampleusestickersAns')} <Link class="goToVideo" to="">Go to Video</Link></p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>


                            <div className="tab-pane fade" id="tab-8" role="tabpanel" tabIndex="0">
                                <div className="accordion faqStyle">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#candy_1">
                                            {t('WhatCandyChallenge')}
                                            </button>
                                        </h2>
                                        <div id="candy_1" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WhatCandyChallengeAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#candy_2">
                                            {t('checktheCandyscore')}
                                            </button>
                                        </h2>
                                        <div id="candy_2" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('checktheCandyscoreAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#candy_3">
                                            {t('earnadditionalCandy')}
                                            </button>
                                        </h2>
                                        <div id="candy_3" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('earnadditionalCandyAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#candy_4">
                                            {t('freeDomeAddress')}
                                            </button>
                                        </h2>
                                        <div id="candy_4" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('freeDomeAddressAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#candy_5">
                                            {t('participateCandy')}
                                            </button>
                                        </h2>
                                        <div id="candy_5" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('participateCandyAns')} <Link class="goToVideo" to="">Go to Video</Link></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade" id="tab-9" role="tabpanel" tabIndex="0">
                                <div className="accordion faqStyle">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#green_1">
                                            {t('WhatisGreen')}
                                            </button>
                                        </h2>
                                        <div id="green_1" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('WhatisGreenAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#green_2">
                                            {t('earnParticipation')}
                                            </button>
                                        </h2>
                                        <div id="green_2" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('earnParticipationAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#green_3">
                                            {t('updatetheparticipation')}
                                            </button>
                                        </h2>
                                        <div id="green_3" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('updatetheparticipationAns')}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#green_4">
                                            {t('Howdonation')}
                                            </button>
                                        </h2>
                                        <div id="green_4" className="accordion-collapse collapse" data-bs-parent="#accordionFaq">
                                            <div className="accordion-body">
                                                <p>{t('HowdonationAns')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq;
