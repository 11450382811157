const featuresData = [
    {
        title: "Flash",
        description: "Tap to catch the flash icon to win great prizes as well as cash points offered by the event.",
        icon: "flash-icon.svg"
    },
    {
        title: "Candy",
        description: "Tap to catch the candy icon to be the high score holder of the world along with benefits",
        icon: "candy-icon.svg"
    },
    {
        title: "Points",
        description: "Tap to catch the star icon to get points used as cash",
        icon: "points-icon.svg"
    },
    {
        title: "Stickers",
        description: "Tap to catch the sticker icon to collect and own your favorite LoaMoa character stickers",
        icon: "stickers-icon.svg"
    },
    {
        title: "Green",
        description: "Tap to catch the green icon to donate to environmental protection campaings and earn badges.",
        icon: "green-icon.svg"
    }
]

export default featuresData;