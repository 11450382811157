const FeaturesCard = ({items}) => {
    return (
        <div className="features-card">
            <figure>
                <img src={`images/${items.icon}`} alt="icon" />
            </figure>
            <figcaption>
                <h4>{items.title}</h4>
                <p>{items.description}</p>
            </figcaption>
        </div>
    )
}

export default FeaturesCard;
