import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { useTranslation } from 'react-i18next';

const Dome = () => {
    const { t } = useTranslation();

    let currentLang = "https://loamoa-dev-merge.cladev.com?" + localStorage.getItem('lang');

    useEffect(()=>{
        document.title = 'LoaMoa || Dome';
        window.scrollTo(0, 0);
    },[]);

    var custDomeSettings = {
        infinite: true,
        speed: 500,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 3
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2
                }
              }
        ]
    }

  return (
    <>
        <section className='dome-banner'>
            <div className="container px-4">
                <div className='dome-banner__text'>
                    <h2>{t('Planet')}</h2>
                    <p>{t('metaverse')}<br />{t('Obtain')}</p>
                </div>
            </div>
        </section>

        <section className='section dome-address'>
            <div className="container px-2">
                <div className='dome-address__titleblock'>
                    <h2>{t('Dome Address')}</h2>
                    <p>{t('location')}</p>
                </div>
            </div>
        </section>

        <section className='section dome-address-master'>
            <div className="container px-4">
                <div className='dome-address-master__titleblock'>
                    <h2>{t('Dome Address')} <small className='master-tag'>{t('Master')}</small></h2>
                    <p>{t('Get')}</p>
                    <Link target='_blank' to={currentLang} className="btn btn-primary">{t('Buy Now $18/Yr')}</Link>
                </div>
                <div className="row">
                    <div className="col-md-10 offset-md-1">
                        <div className='dome-address-master__card'>
                            <div className="row">
                                <div className="col-md-7 dome-address-master__card__left">
                                    <h4 className='size24'>{t('Voucher Bundle')}</h4>
                                    <p className='font-medium'>{t('economical')}</p>
                                    <hr className='lineb' />
                                    <h3 className='mb-0'>{t("Product")}</h3>
                                    <span className='size18 font-medium'>{t('promotion')}</span>
                                    <h4 className='size24 mt-15'>US$ <del>229.50</del> → $ 115.00 {t('50% Discount')}</h4>
                                </div>
                                <div className="col-md-5 dome-address-master__card__right">
                                    <h4>{t('purchase')}</h4>
                                    <div className='blue-card'>
                                        <ul>
                                            <li>{t('Switch')}</li>
                                            <li>{t('Master Login on the web')}</li>
                                            <li>{t("Click Lbox")}</li>
                                            <li>{t('Click Buy')}</li>
                                            <li>{t('debit card')}</li>
                                        </ul>
                                        <div className="text-center">
                                            <Link target='_blank' to={currentLang} className="btn btn-white font-bold text-primary btn-long">{t('Buy Now')}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className='section dome-build'>
            <div className="container px-4">
                <div className='row g-5'>
                    <div className="col-md-6 dome-build__left">
                        <img src="images/dome-build-merge.png" alt="fig" />
                    </div>
                    <div className="col-md-6 dome-build__right">
                        <h2>{t('Dome Build')} <small className='master-tag'>{t('Master')}</small></h2>
                        <h2 className='singleStyle'>{t('Dome')}</h2>
                        <h3>{t('Your 3D virtual space')}</h3>
                        <p>{t('Unlike')}</p>
                        <p>{t("Users")}</p>
                        <p>{t('Additionally')}</p>
                    </div>
                </div>
            </div>
        </section>



        <section className='section customizing-dome'>
            <div className="container px-4">
                <div className='how-to-build-dome'>
                    <h2 className='how-to-build-dome__heading'>{t("How to build a Dome")}</h2>
                    <div className='row how-to-build-dome__row'>
                        <div className='col build-steps'>
                            <h4 className='build-steps__title master_login'>{t('Master Login')}</h4>
                            <figure className='build-steps__frame'>
                                <img src="images/dome-address-reg.svg" alt="fig" />
                            </figure>
                            <figcaption>{t('Dome Address')} <br /> {t("Registration")}</figcaption>
                        </div>
                        <div className='col build-steps'>
                            <figure className='build-steps__frame'>
                                <img src="images/dome-settings.svg" alt="fig" />
                            </figure>
                            <figcaption>{t('Dome')} <br /> {t('Settings')}</figcaption>
                        </div>
                        <div className='col build-steps'>
                            <figure className='build-steps__frame'>
                                <img src="images/dome-editing.svg" alt="fig" />
                            </figure>
                            <figcaption>{t('Dome')} <br /> {t('Editing')}</figcaption>
                        </div>
                        <div className='col build-steps'>
                            <figure className='build-steps__frame'>
                                <img src="images/publishing.svg" alt="fig" />
                            </figure>
                            <figcaption>{t('Publishing')}</figcaption>
                        </div>
                        <div className='col build-steps'>
                            <h4 className='build-steps__title arshow_creation'>{t('ARshow creation')}</h4>
                            <figure className='build-steps__frame'>
                                <img src="images/dome-admin.svg" alt="fig" />
                            </figure>
                            <figcaption>{t('Dome')} <br /> {t('Admin')}</figcaption>
                        </div>
                    </div>
                </div>

                <div className='customizing-dome__slider'>
                    <h2>{t('Endless')}</h2>
                    <Slider className="domeSlider" {...custDomeSettings}>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome003_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('3d Website')}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome006_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('Fan Club')}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome009_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('Museum')}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome012_ar.png" alt="fig" />
                            </figure>
                            <h5>{t("Arena")}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome015_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('Showroom')}</h5>
                        </div>

                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome018_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('Art/NFT Gallery')}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome021_ar.png" alt="fig" />
                            </figure>
                            <h5>{t("Pop-up Store")}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome024_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('Campaign')}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome027_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('Promotional Event')}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome030_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('Academy')}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome033_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('Shopping Mall')}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome036_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('ESG Management')}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome039_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('Influencer Community')}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome042_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('Mission Center')}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome045_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('Private Hall')}</h5>
                        </div>
                        <div className='domeSlider__slide'>
                            <figure className='domeSlider__slide__fig'>
                                <img src="images/Dome048_ar.png" alt="fig" />
                            </figure>
                            <h5>{t('Memorial Hall')}</h5>
                        </div>
                    </Slider>
                </div>

                <div id='arshowSection' className="row g-5 align-items-center">
                    <div className="col-md-5">
                        <img src="images/dome-arshow-mobile.png" alt="Mobile" />
                    </div>
                    <div className="col-md-7">
                        <h2>{t('ARshow')}</h2>
                        <p>{t('short-form')}</p>
                        <p className='mb-40'>{t('virality')}</p>
                        <h2>{t('ARshow for a Certified Shot')}</h2>
                        <p>{t("create certified")}</p>
                    </div>
                </div>
            </div>
        </section>


        <section className='section pb-50 overflow-hidden'>
            <div className="container px-4">
                <div className='arshow-container'>
                    <div className="row g-5">
                        <div className='col-md-5 arshow-container__fig'>
                            <img src="images/hot-spot-arshow.png" alt="fig" />
                        </div>
                        <div className='col-md-7 arshow-container__infoText'>
                            <h3 className='size30'>{t('your desired')}</h3>
                            <h2>{t('ARshow')} <small className='master-tag'>{t("Master")}</small></h2>
                            <p>{t('Fill the ARshow')}</p>
                            <p>{t('Also')}</p>
                            <p>{t('The LoaMoa donation')}</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-10 offset-md-1 mt-50 mb-50">
                    <div className="row gx-5 createArshow">
                        <div className="col-md-6 createArshow__left" >
                            <h3 className='size30'>{t('How to Publish a Dome for')}</h3>
                            <h2>{t('Free to Create ARshows')}</h2>
                        </div>
                        <div className="col-md-6 createArshow__right">
                            <p>{t('By participating')}</p>
                        </div>
                    </div>
                </div>


                <div className='section-header'>
                    <h2><span className='text-dark'>{t('ARshow')}</span> <small className='master-tag'>{t('Master')}</small></h2>
                    <p>{t('Create ARshow')} </p>
                    <Link className='btn btn-primary' to="https://ezgif.com/">{t('Make a GIF')}</Link>
                </div>

            </div>
        </section>

        <section className='section how-to-use-arshow'>
            <div className="container px-4">
                <div className='section-header'>
                    <h2 className='text-dark mb-50'>{t('How to Use ARshow Maker')}</h2>
                </div>
                <div className="row gx-5">
                    <div className="col-md-6 col-lg-3 arshow-marker">
                        <div className='arshow-marker__fig'>
                            <div className='arshow-marker__fig--circle'>
                                <h3>1</h3>
                                <span>{t('Step')}</span>
                            </div>
                            <img src="images/arshow-marker-1.png" alt="arshow" />
                        </div>
                        <h4 className='arshow-marker__name'>{t('Go to ezgif Website')}</h4>
                    </div>
                    <div className="col-md-6 col-lg-3 arshow-marker">
                        <div className='arshow-marker__fig'>
                            <div className='arshow-marker__fig--circle'>
                                <h3>2</h3>
                                <span>{t('Step')}</span>
                            </div>
                            <img src="images/arshow-marker-2.png" alt="arshow" />
                        </div>
                        <h4 className='arshow-marker__name'>{t('Choose Your Files')}</h4>
                    </div>
                    <div className="col-md-6 col-lg-3 arshow-marker">
                        <div className='arshow-marker__fig'>
                            <div className='arshow-marker__fig--circle'>
                                <h3>3</h3>
                                <span>{t('Step')}</span>
                            </div>
                            <img src="images/arshow-marker-3.png" alt="arshow" />
                        </div>
                        <h4 className='arshow-marker__name'>{t('Make a GIF')}</h4>
                    </div>
                    <div className="col-md-6 col-lg-3 arshow-marker">
                        <div className='arshow-marker__fig'>
                            <div className='arshow-marker__fig--circle'>
                                <h3>4</h3>
                                <span>{t('Step')}</span>
                            </div>
                            <img src="images/arshow-marker-4.png" alt="arshow" />
                        </div>
                        <h4 className='arshow-marker__name'>{t('Save the GIF')}</h4>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Dome
