import { useState } from "react";
import { FileUpload } from './servicesapi';

const CareersCard = ({ items }) => {
    const locations = [items.location];
    const locationString = locations.join(', ');
    const [isOpen, setisOpen] = useState(false);

    const toggleClass = () => {
        document.body.classList.toggle('isModalOpened');
        setisOpen(!isOpen);
    }

    

    const data = {
        email: "",
        username: "",
        description: "",
        //fileupload: "", 
        location:"",
        position:""
    }
    const [inputData, setInputData] = useState(data);
   // const [imageName, setImageName] = useState({});
   const [selectedImage, setFirstFileName] = useState(null);
   const [firstFile, setFirstFile] = useState(null);

    function handlerData(e) {
        setInputData({ ...inputData, [e.target.name]: e.target.value });
        console.log(inputData, "inputdata.....")
    }
 
    // const handleImageChange = (event) => {
    //     const file = event.target.files[0];
    //     console.log(file,'file')
    //     setSelectedImage(file);
    //     //setImageName(file);
    //   };

    const handleImageChange = async (e) => {
        
          const selectedImage = e.target.files[0];
          const selectedFileName = selectedImage.name;
          const result = await FileUpload(selectedImage);
          const selectedFile = result.file;
              setFirstFileName(selectedFileName);
              setFirstFile(selectedFile);
      };
   

    const handleSubmit=async(e)=> {
            try {
                const response = await fetch(`https://app.loamoa-dev-merge.cladev.com/api/armax/partnerRequest/career_request`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    
                  },
                  body: JSON.stringify({
                    email:inputData.email,
                    username:inputData.username,
                    location:locationString,
                    position:items.creerTitle,
                    description:inputData.description,
                    cv:firstFile
                  }),
                });
                
          
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
          
                // Handle successful response
                  await response.json();
                  alert("Form submit successfully")
                  
              } catch (error) {
                alert('Error during POST request:', error.message);
              } 
        
    } 

    return (
        <>
            <div className='career-loop'>
                <div className='career-loop__header'>
                    <h3 className='career-loop__header__title'>{items.creerTitle}</h3>
                    <div className='career-loop__header__location'>
                        {items.location.map((singleLocation, i) => {
                            return <label key={i} className="locationText">
                                <img src="images/location-icon.svg" alt="icon" /> {singleLocation}
                            </label>
                        })}
                    </div>
                </div>
                <div className='career-loop__body'>
                    <h5 className='font-bold mb-15'>Minimum Qualifications</h5>
                    <ul className='common-list'>
                        {
                            items.jobs.map((singleJobs, i) => {
                                return <li key={i}>{singleJobs}</li>
                            })
                        }
                    </ul>
                    <div className="text-right">
                        <button className='btn btn-primary' onClick={toggleClass}>Apply</button>
                    </div>
                </div>
            </div>
            {isOpen &&
                <>
                <div className={isOpen? 'modal fade show opened':'modal'}>
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="close" onClick={toggleClass}></button>
                                <div className='section-header mt-30'>
                                    <h3>Career</h3>
                                </div>
                                <div className='support-modal'>
                                    <div className="fx-jcfe">
                                        <div className="mb-3">
                                            <button className='btn btn-default btn-sm rounded5'>English Only</button>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="apply_location" className="form-label">Location<sup>*</sup>
                                        </label>
                                        <input type="text" value={items.location} onChange={handlerData} name="location" id="apply_location" className="form-control" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="apply_email" className="form-label">Your email address<sup>*</sup></label>
                                        <input type="email" value={inputData.email} onChange={handlerData} name="email" id="apply_email" className="form-control" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="apply_userName" className="form-label">LoaMoa username(Nickname)<sup>*</sup></label>
                                        <input type="text" value={inputData.username} onChange={handlerData} name="username" id="apply_userName" className="form-control" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="apply_position" className="form-label">Position<sup>*</sup></label>
                                        <input type="text" value={items.creerTitle} onChange={handlerData} name="position" id="apply_position" className="form-control" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="apply_description" className="form-label">Description<sup>*</sup></label>
                                        <textarea name="description" value={inputData.description} onChange={handlerData} id="apply_description" rows="4" className='form-control' placeholder='Please describe the inquires in specific details'></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="apply_fileName" className="form-label">CV<sup>*</sup></label>
                                        <div className='position-relative fileUploadArea'>
                                            <span>{selectedImage ? selectedImage : 'File name'}</span>
                                            {/* <input type="text" className="form-control" name="apply_fileName" id="apply_fileName" /> */}
                                            <label htmlFor="apply_fileUpload" className='fileUpload'>
                                                <input type="file" onChange={handleImageChange} name="fileupload" id="apply_fileUpload" />
                                                Add File
                                            </label>
                                        </div>
                                    </div>
                                    <div className='text-center mt-15'>
                                        <button data-bs-dismiss="modal" aria-label="Close" type='submit' onClick={handleSubmit} className='btn btn-primary btn-long font-bold'>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-backdrop fade show" onClick={toggleClass}></div>
                </>
            }
        </>
    )
}

export default CareersCard
