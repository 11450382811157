import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Dome from './pages/Dome';
import Features from './pages/Features';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsofUse from './pages/TermsofUse';
import Careers from './pages/Careers';
import Faq from './pages/Faq';
import PrivacyPolicyApp from './pages/PrivacyPolicyApp';
import TermsofUseApp from './pages/TermsofUseApp';



function App() {
  

  return (
    <div className='wapper'>
      <Router>
          <Header />
          <Routes>
              <Route path="/" element={<Home />} exact></Route>
              <Route path="/dome" element={<Dome />}></Route>
              <Route path="/features" element={<Features />}></Route>
              <Route path="/about" element={<About />}></Route>
              <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
              <Route path="/terms-of-use" element={<TermsofUse />}></Route>
              <Route path="/careers" element={<Careers />}></Route>
              <Route path="/faq" element={<Faq />}></Route>

              <Route path="/privacy-policy-app" element={<PrivacyPolicyApp />}></Route>
              <Route path="/terms-of-use-app" element={<TermsofUseApp />}></Route>
              
          </Routes>
          <Footer />
      </Router>
    </div>
  );
}

export default App;
