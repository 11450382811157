import { useEffect } from 'react';


import PrivacyPolicyContent from '../components/PrivacyPolicyContent';

const PrivacyPolicy = () => {
    
    useEffect(() => {
        document.title = 'LoaMoa || Privacy Policy';
        window.scrollTo(0, 0);
    }, []);

  return (
    <>
        <PrivacyPolicyContent />
    </>
  )
}

export default PrivacyPolicy
