import { useEffect } from 'react';
import TermsofUseContent from '../components/TermsofUseContent';


const TermsofUse = () => {
    useEffect(() => {
        document.title = 'LoaMoa || Terms of Use';
        window.scrollTo(0, 0);

        let mainHeader = document.querySelector(".mainHeader")
        let footermain = document.querySelector(".footer-main")
        let innerBanner = document.querySelector(".content-page-banner")
        mainHeader.style.display = 'none';
        footermain.style.display = 'none';
        innerBanner.style.paddingTop = '25px';
        innerBanner.style.minHeight = '100px';
    }, []);

  return (
    <>
        <TermsofUseContent />
    </>
  )
}

export default TermsofUse
