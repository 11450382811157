import { useState } from 'react';
import { Link, NavLink,} from 'react-router-dom';
import Langi18next from './LanguageTranslate/Langi18next';
import { useTranslation } from 'react-i18next';
// import Select from 'react-select';

function Header() {
    const { t } = useTranslation();
    const [isActive, setActive] = useState(false);
    let localLang = localStorage.getItem('lang');
    let currentLang = "https://loamoa-dev-merge.cladev.com?" + localStorage.getItem('lang');

    let androidUrl = `https://play.google.com/store/apps/details?id=com.loamoa&hl=${localLang}_BO&pli=1`




    const menuToggleClass = () => {
        setActive(!isActive);
    }

   

    return (
        <header className="mainHeader">
            <div className="container">
                <Link to="/" className="mainHeader__logo">
                    <img src="images/loamoa-logo.svg" alt="LoaMoa" />
                </Link>
                <div className="mainHeader__rightSide">
                    <div className={isActive ? 'combindBlock opened' : 'combindBlock'}>
                        <div className="forMobile">
                            <Link to="/" onClick={menuToggleClass} className='d-inline-block'>
                                <img src="images/loamoa-logo.svg" alt="LoaMoa" />
                            </Link>
                        </div>
                        <nav>
                            <ul>
                                <li><NavLink onClick={menuToggleClass} to="/dome">{t('DOME')}</NavLink></li>
                                <li><NavLink onClick={menuToggleClass} to="/features">{t('FEATURES')}</NavLink></li>
                                <li><NavLink onClick={menuToggleClass} to="/faq">{t('SUPPORT')}</NavLink></li>
                                <li className='master-login-mobile'>
                                    <Link to={currentLang}>
                                    <img className="mr-5" src="images/login-icon-white.svg" alt="icon" /> 
                                    {t('Master Login')}
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        <div className="downloadAppBtn">
                            <span>{t('Download App')}</span>
                            <Link target='_blank' to="https://apps.apple.com/tc/app/loamoa/id1500906138"><img src="images/app-store-icon.svg" alt="icon" /></Link>
                            <Link target='_blank' to={androidUrl}><img src="images/play-store-icon.svg" alt="icon" /></Link>
                        </div>

                        <button className='menuClose' onClick={menuToggleClass}></button>
                    </div>
                    
                    <div className="selectLanguage">
                        {/* <Select
                            className='custom-react-select'
                            classNamePrefix='custom-react-select'
                            defaultValue={selectedOption}
                            onChange={setSelectedOption}
                            options={options}
                        /> */}
                        <Langi18next />
                    </div>

                    <div className="menuBtn" onClick={menuToggleClass}>
                        <span className="menuBtn__line one"></span>
                        <span className="menuBtn__line two"></span>
                        <span className="menuBtn__line three"></span>
                    </div>
                </div>
            </div>
            <Link target="_blank" to={currentLang} className="master-login-btn">
                <img className="mr-5" src="images/login-icon.svg" alt="icon" /> {t('Master Login')}
            </Link>
        </header>
    )
}
export default Header;