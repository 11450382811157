import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PrivacyPolicyContent = () => {
    const { t } = useTranslation();

  return (
    <>
      <section className='content-page-banner smaller'>
            <div className="container">
                <h2>LoaMoa {t('Privacy Policy')}</h2>
            </div>
        </section>
        <section className='mission-sec'>
            <div className="container px-4">
                <div className="content-page">

                    <p>{t('Through this Policy')}</p>

                    <h4 className='text-primary'>{t('Information to be collected')}</h4>

                    <h5 className='font-bold'>{t('Personal information')}</h5>
                    <p>{t('Personal informationitems')}:</p>
                    <p>{t('Information provided')}</p>
                    <p>{t('The Company may')}</p>

                    <ul>
                        <li>{t('TheCompany_1')}</li>
                        <li>{t('TheCompany_2')}</li>
                        <li>{t('TheCompany_3')}</li>
                        <li>{t('TheCompany_4')}</li>
                        <li>{t('TheCompany_5')}</li>
                        <li>{t('TheCompany_6')}</li>
                        <li>{t('TheCompany_7')}</li>
                        <li>{t('TheCompany_8')}</li>
                        <li>{t('TheCompany_9')}</li>
                    </ul>

                    <h5 className='font-bold'>{t('TheCompany_10')}</h5>

                    <p>{t('TheCompany_11')}</p>
                    <ul>
                        <li></li>
                        <li>{t('TheCompany_12')}</li>
                        <li>{t('TheCompany_13')}</li>
                    </ul>

                    <h4 className='text-primary'> {t('Use of collected information')}</h4>

                    <p>{t('Useofcollected_1')}</p>

                    <ul>
                        <li>{t('Useofcollected_2')}</li>
                        <li>{t('Useofcollected_3')}</li>
                        <li>{t('Useofcollected_4')}</li>
                        <li>{t('Useofcollected_5')}</li>
                        <li>{t('Useofcollected_6')}</li>
                        <li>{t('Useofcollected_7')}</li>
                        <li>{t('Useofcollected_8')}</li>
                        <li>{t('Useofcollected_9')}</li>
                        <li>{t('Useofcollected_10')}</li>
                        <li>{t('Useofcollected_11')}</li>
                    </ul>

                    <p>{t('Useofcollected_12')}</p>

                    <h4 className='text-primary'>{t('Use of collected')}</h4>

                    <p>{t('DisclosureCollected_1')}</p>

                    <ul>
                        <li>{t('DisclosureCollected_2')}</li>
                        <li>{t('DisclosureCollected_3')}</li>
                        <li>{t('DisclosureCollected_4')}</li>
                        <li>{t('DisclosureCollected_5')}</li>
                        <li>{t('DisclosureCollected_6')}</li>
                        <li>{t('DisclosureCollected_7')}</li>
                        <li>{t('DisclosureCollected_8')}</li>
                    </ul>

                    <p>{t('DisclosureCollected_9')}</p>
                    <p>{t('DisclosureCollected_10')}</p>


                    <h4 className='text-primary'>{t('Cookies, Beacons and Similar Technologies')}</h4>

                    <p>{t('CookiesBeacons_1')}</p>
                    <p>{t('CookiesBeacons_2')}</p>
                    <p>{t('CookiesBeacons_3')}</p>
                    <p>{t('CookiesBeacons_4')}</p>
                    <p>{t('CookiesBeacons_5')}</p>

                    <ul>
                        <li>{t('CookiesBeacons_6')}</li>
                        <li>{t('CookiesBeacons_7')}</li>
                        <li>{t('CookiesBeacons_8')}</li>
                        <li>{t('CookiesBeacons_9')}</li>
                    </ul>
                    <p>{t('CookiesBeacons_10')}</p>

                    <h4 className='text-primary'>{t('User’s right')}</h4>

                    <p>{t('Usersright_1')}</p>

                    <ul>
                        <li>{t('Usersright_2')}</li>
                        <li>{t('Usersright_3')}</li>
                        <li>{t('Usersright_4')}</li>
                        <li>{t('Usersright_5')}</li>
                    </ul>

                    <p>{t('Usersright_6')}</p>

                    <h4 className='text-primary'>{t('Security')}</h4>

                    <p>{t('Security_1')}</p>
                    <p>{t('Security_2')}</p>

                    <ul>
                        <li>{t('Security_3')}</li>
                        <li>{t('Security_4')}</li>
                        <li>{t('Security_5')}</li>
                        <li>{t('Security_6')}</li>
                        <li>{t('Security_7')}</li>
                        <li>{t('Security_8')}</li>
                        <li>{t('Security_9')}</li>
                    </ul>

                    <h4 className='text-primary'>{t('Protection of personal information of children')}</h4>

                    <p>{t('Protection_1')}</p>
                    <p>{t('Protection_2')}</p>

                    <ul>
                        <li>{t('Protection_3')}</li>
                        <li>{t('Protection_4')}</li>
                        <li>{t('Protection_5')}</li>
                        <li>{t('Protection_6')}</li>
                        <li>{t('Protection_7')}</li>
                    </ul>


                    <h4 className='text-primary'>{t('Modification of Privacy Protection Policy')}</h4>
                    <p>{t('The Company has the right')}</p>

                    <h4 className='text-primary'>{t('Contact information of Company')}</h4>
                    <p>{t('Contactinfo_1')}</p>
                    <p>{t('Contactinfo_2')}</p>
                    <p>{t('Contactinfo_3')}</p>
                    <p>{t('Contactinfo_4')}</p>
                    <p>{t('Contactinfo_5')} <Link to="mailto:support@loamoa.com">{t('Contactinfo_5_1')}</Link></p>
                    <p>{t('Contactinfo_6')}</p>

                    <h4 className='text-primary'>{t('Appendix of Privacy Protection Policy')}</h4>
                    <h5 className='font-bold'>{t('Lawful processing')}</h5>

                    <p>{t('AppendixPrivacy_1')}</p>

                    <ul>
                        <li>{t('AppendixPrivacy_2')}</li>
                        <li>{t('AppendixPrivacy_3')}</li>
                        <li>{t('AppendixPrivacy_4')}</li>
                        <li>{t('AppendixPrivacy_5')}</li>
                        <li>{t('AppendixPrivacy_6')}</li>
                        <li>{t('AppendixPrivacy_7')}</li>
                        <li>{t('AppendixPrivacy_8')}</li>
                        <li>{t('AppendixPrivacy_9')}</li>
                        <li>{t('AppendixPrivacy_10')}</li>
                        <li>{t('AppendixPrivacy_11')}</li>
                    </ul>


                    <h5 className='font-bold'>{t('User’s right when applying GDPR')}</h5>
                    <p>{t('GDPR_1')}</p>

                    <ul>
                        <li>{t('GDPR_2')}</li>
                        <li>{t('GDPR_3')}</li>
                        <li>{t('GDPR_4')}</li>
                        <li>{t('GDPR_5')}</li>
                        <li>{t('GDPR_6')}</li>
                        <li>{t('GDPR_7')}</li>
                        <li>{t('GDPR_8')}</li>
                        <li>{t('GDPR_9')}</li>
                        <li>{t('GDPR_10')}</li>
                        <li>{t('GDPR_11')}</li>
                        <li>{t('GDPR_12')}</li>
                        <li>{t('GDPR_13')}</li>
                        <li>{t('GDPR_14')}</li>
                        <li>{t('GDPR_15')}</li>
                        <li>{t('GDPR_16')}</li>
                    </ul>

                    <h5 className='font-bold'>{t('3. Data transfer to other countries')}</h5>
                    <p>{t('Considering it engages')}</p>
                    <p>{t('In addition')}</p>


                    <h5 className='font-bold'>{t('3rd partys sites and services')}</h5>
                    <p>{t('The website')}</p>


                    <h5 className='font-bold'>{t('Guide for users')}</h5>
                    <p>{t('If the user')}</p>
                    <p>{t('In case of')}</p>
                    <p>{t('Moreover, the')}</p>


                    <h5 className='font-bold'>{t('Guide for users residing in Korea')}</h5>
                    <p>{t('residingKorea_1')}</p>

                    <h5 className='font-medium'>{t('residingKorea_2')}</h5>
                    <p>{t('residingKorea_3')}</p>
                    
                    <ul>
                        <li>{t('residingKorea_4')}</li>
                        <li>{t('residingKorea_5')}</li>
                        <li>{t('residingKorea_6')}</li>
                        <li>{t('residingKorea_7')}</li>
                        <li>{t('residingKorea_8')}</li>
                        <li>{t('residingKorea_9')}</li>
                        <li>{t('residingKorea_10')}</li>
                        <li>{t('residingKorea_11')}</li>
                        <li>{t('residingKorea_12')}</li>
                        <li>{t('residingKorea_13')}</li>
                        <li>{t('residingKorea_14')}</li>
                        <li>{t('residingKorea_15')}</li>
                        <li>{t('residingKorea_16')}</li>
                    </ul>

                    <h5 className='font-medium'>{t('Period for retention')}</h5>
                    <p>{t('Period_1')}</p>
                    <p>{t('Period_2')}</p>

                    <h5 className='font-medium'>{t('Procedure and method')}</h5>
                    <p>{t('Procedure_1')}</p>


                    <h5 className='font-medium'>{t('Technical managerial')}</h5>
                    <p>{t('Technical_1')}</p>

                    <ul>
                        <li>{t('Technical_2')}</li>
                        <li>{t('Technical_3')}</li>
                        <li>{t('Technical_4')}</li>
                    </ul>

                    <h5 className='font-medium'>{t('Staff responsible')}</h5>

                    <p>{t('Staffresponsible_1')}</p>
                    <p>{t('Staffresponsible_2')}</p>
                    <p>{t('Staffresponsible_3')}</p>
                    <p>{t('Staffresponsible_4')}</p>
                    <p>{t('Staffresponsible_5')}</p>
                    <p>{t('Staffresponsible_6')}</p>
                </div>
            </div>
        </section>
    </>
  )
}

export default PrivacyPolicyContent;
