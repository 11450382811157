import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TermsofUseContent = () => {
    const { t } = useTranslation();


  return (
    <>
      <section className='content-page-banner smaller'>
            <div className="container">
                <h2>{t('Terms of Use')}</h2>
            </div>
        </section>

        <section className='mission-sec'>
            <div className="container px-4">
                <div className="content-page">
                    <p>{t('governs the access')}</p>

                    <p>{t('legally binding agreement')}</p>

                    <h4 className='text-primary'>{t('Accepting the Terms')}</h4>
                    <p>{t('By_accessing_or')}</p>

                    <p>{t('YourAccessto')}</p>

                    <p>{t('Lcash_refers_to')}</p>

                    <p>{t('If you access')}</p>
                    

                    <h4 className='text-primary'>{t('LoaMoa Account')}</h4>
                    <p>{t('To access or use')}</p>

                    <p>{t('You agree that')}</p>

                    <p>{t('You are not allowed')}</p>

                    <p>{t('If you no longer')}</p>

                    <h4 className='text-primary'>{t('Use of LoaMoa Services')}</h4>
                    <p>{t('UseLoaMoaServ_1')}</p>

                    <p>{t('UseLoaMoaServ_2')}</p>

                    <ul>
                        <li>{t('UseLoaMoaServ_3')}</li>

                        <li>{t('UseLoaMoaServ_4')}</li>

                        <li>{t('UseLoaMoaServ_5')}</li>

                        <li>{t('UseLoaMoaServ_6')}</li>

                        <li>{t('UseLoaMoaServ_7')}</li>

                        <li>{t('UseLoaMoaServ_8')}</li>

                        <li>{t('UseLoaMoaServ_9')}</li>

                        <li>{t('UseLoaMoaServ_10')}</li>

                        <li>{t('UseLoaMoaServ_11')}</li>

                        <li>{t('UseLoaMoaServ_12')}</li>

                        <li>{t('UseLoaMoaServ_13')}</li>

                        <li>{t('UseLoaMoaServ_14')}</li>

                        <li>{t('UseLoaMoaServ_15')}</li>

                        <li>{t('UseLoaMoaServ_16')}</li>

                        <li>{t('UseLoaMoaServ_17')}</li>

                        <li>{t('UseLoaMoaServ_18')}</li>

                        <li>{t('UseLoaMoaServ_19')}</li>
                        
                        <li>{t('UseLoaMoaServ_20')}</li>

                        <li>{t('UseLoaMoaServ_21')}</li>

                        <li>{t('UseLoaMoaServ_22')}</li>

                        <li>{t('UseLoaMoaServ_23')}</li>

                        <li>{t('UseLoaMoaServ_24')}</li>

                        <li>{t('UseLoaMoaServ_25')}</li>

                        <li>{t('UseLoaMoaServ_26')}</li>

                        <li>{t('UseLoaMoaServ_27')}</li>

                        <li>{t('UseLoaMoaServ_28')}</li>

                        <li>{t('UseLoaMoaServ_29')}</li>
                    </ul>

                    <p>{t('UseLoaMoaServ_30')}</p>

                    <p>{t('UseLoaMoaServ_31')}</p>



                    <h4 className='text-primary'>{t('CopyrightPolicy')}</h4>
                    <p>{t('CopyrightPolicy_1')}</p>

                    <h5 className='font-bold'>{t('CopyrightPolicy_2')}</h5>
                    <p>{t('CopyrightPolicy_3')}</p>

                    <p>{t('CopyrightPolicy_4')}</p>

                    <p>{t('CopyrightPolicy_5')}</p>

                    <p>{t('CopyrightPolicy_6')}</p>

                    <h5 className='font-bold'>{t('CopyrightPolicy_7')}</h5>
                    <p>{t('CopyrightPolicy_8')}</p>

                    <p>{t('CopyrightPolicy_9')}</p>

                    <p>{t('CopyrightPolicy_10')}</p>

                    <p>{t('CopyrightPolicy_11')}</p>

                    <p>{t('CopyrightPolicy_12')}</p>

                    <p>{t('CopyrightPolicy_13')}</p>

                    <p>{t('CopyrightPolicy_14')}</p>

                    <p>{t('CopyrightPolicy_15')}</p>

                    <p>{t('CopyrightPolicy_16')}</p>

                    <p>{t('CopyrightPolicy_17')}  <Link to="mailto:support@loamoa.com">support@loamoa.com</Link>   {t('CopyrightPolicy_17_1')}</p>

                    <p>{t('CopyrightPolicy_18')}</p>
                    <ul className='common-list'>
                        <li>{t('CopyrightPolicy_19')}</li>
                        <li>{t('CopyrightPolicy_20')}</li>
                        <li>{t('CopyrightPolicy_21')}</li>
                    </ul>


                    <h4 className='text-primary'>{t('Exclusion of warranties')}</h4>
                    <p>{t('ExclusionWar_1')}</p>

                    <p>{t('ExclusionWar_2')}</p>

                    <p>{t('ExclusionWar_3')}</p>

                    <ul className='common-list'>
                        <li>{t('ExclusionWar_4')}</li>
                        <li>{t('ExclusionWar_5')}</li>
                        <li>{t('ExclusionWar_6')}</li>
                        <li>{t('ExclusionWar_7')}</li>
                        <li>{t('ExclusionWar_8')}</li>
                        <li>{t('ExclusionWar_9')}</li>
                        <li>{t('ExclusionWar_10')}</li>
                        <li>{t('ExclusionWar_11')}</li>
                    </ul>

                    <p>{t('ExclusionWar_12')}</p>

                    <p>{t('ExclusionWar_13')}</p>

                    <h4 className='text-primary'>{t('Indemnity')}</h4>
                    <p>{t('Indemnity_1')}</p>

                    <ul className='common-list'>
                        <li>{t('Indemnity_2')}</li>
                        <li>{t('Indemnity_3')}</li>
                        <li>{t('Indemnity_4')}</li>
                    </ul>


                    <h4 className='text-primary'>{t('Limitation of liability')}</h4>
                    <p>{t('Limitation_1')}</p>

                    <h5 className="font-bold">{t('Limitation_2')}</h5>
                    <p>{t('Limitation_3')}</p>

                    <p>{t('Limitation_4')}</p>

                    <ul className='common-list'>
                        <li>{t('Limitation_5')}</li>
                        <li>{t('Limitation_6')}</li>
                        <li>{t('Limitation_7')}</li>
                        <li>{t('Limitation_8')}</li>
                        <li>{t('Limitation_9')}</li>
                        <li>{t('Limitation_10')}</li>
                        <li>{t('Limitation_11')}</li>
                        <li>{t('Limitation_12')}</li>
                        <li>{t('Limitation_13')}</li>
                        <li>{t('Limitation_14')}</li>
                        <li>{t('Limitation_15')}</li>
                        <li>{t('Limitation_16')}</li>
                        <li>{t('Limitation_17')}</li>
                        <li>{t('Limitation_18')}</li>
                        <li>{t('Limitation_19')}</li>
                    </ul>

                    <p>{t('Limitation_20')}</p>

                    <p>{t('Limitation_21')}</p>

                    <p>{t('Limitation_22')}</p>

                    <p>{t('Limitation_23')}</p>

                    <h5 className="font-bold">{t('Limitation_24')}</h5>
                    <p>{t('Limitation_25')}</p>

                    <ul className='common-list'>
                        <li>{t('Limitation_26')}</li>
                        <li>{t('Limitation_27')}</li>
                    </ul>

                    <h5 className="font-bold">{t('Limitation_28')}</h5>
                    <p>{t('Limitation_29')}</p>

                    <h4 className='text-primary'>{t('Others')}</h4>
                    <p>{t('Others1')}</p>

                    <p>{t('Others2')}</p>

                    <p>{t('Others3')}</p>

                    <p>{t('Others4')}</p>

                    <p>{t('Others5')}</p>

                    <p>{t('Others6')}</p>

                    <p>{t('Others7')}</p>

                    <p>{t('Others8')}</p>

                    <p>{t('Others9')}</p>

                    <p>{t('Others10')}</p>

                    <ul className='common-list'>
                        <li>{t('Others11')}</li>
                        <li>{t('Others12')}</li>

                    </ul>
                    <p>{t('Others13')}</p>

                    <p>{t('Others14')}</p>

                    <p>{t('Others15')}</p>

                    <h4 className="text-primary">{t('Changes to the Terms')}</h4>
                    <p>{t('ChangesTerms_1')}</p>

                    <h4 className="text-primary">{t('Contact')}</h4>
                    <p>{t('Contact_1')} <Link to="mailto:support@loamoa.com">support@loamoa.com</Link> {t('Contact_1_1')}</p>


                    <h4 className='text-center'>{t('Contact_2')}</h4>
                    <p>{t('Contact_3')}</p>

                    <ul className='common-list'>
                        <li>{t('Contact_4')}</li>
                        <li>{t('Contact_5')}</li>
                        <li>{t('Contact_6')}</li>
                        <li>{t('Contact_7')}</li>
                        <li>{t('Contact_8')}</li>
                        <li>{t('Contact_9')}</li>
                        <li>{t('Contact_10')}</li>
                        <li>{t('Contact_11')}</li>
                        <li>{t('Contact_12')}</li>
                        <li>{t('Contact_13')}</li>
                        <li>{t('Contact_14')}</li>

                        <li>{t('Contact_15')}</li>

                        <li>{t('Contact_16')}</li>

                        <li>{t('Contact_17')}</li>
                    </ul>

                    <h4 className='text-center'>{t('Contact_18')}</h4>
                    <p>{t('Contact_19')}</p>

                    <p>{t('Contact_20')}</p>
                    <ul>
                        <li>{t('Contact_21')}</li>
                    </ul>

                    <p>{t('Contact_22')}</p>

                    <p>{t('Contact_23')}</p>

                    <p>{t('Contact_24')}</p>

                    <p>{t('Contact_25')}</p>

                    <p>{t('Contact_26')}</p>

                    <ul className='common-list'>
                        <li>{t('Contact_27')}</li>
                        <li>{t('Contact_28')}</li>
                        <li>{t('Contact_29')}</li>
                        <li>{t('Contact_30')}</li>
                    </ul>

                    <p>{t('Contact_31')}</p>

                    <ul className='common-list'>
                        <li>{t('Contact_32')}</li>
                        <li>{t('Contact_33')}</li>
                        <li>{t('Contact_34')}</li>
                    </ul>

                    <p>{t('Contact_35')}</p>

                    <ul className='common-list'>
                        <li>{t('Contact_36')}</li>
                        <li>{t('Contact_37')}</li>
                        <li>{t('Contact_38')}</li>
                    </ul>

                    <p>{t('Contact_39')}</p>

                    <ul className='common-list'>
                        <li>{t('Contact_40')}</li>
                        <li>{t('Contact_41')}</li>
                        <li>{t('Contact_42')}</li>
                    </ul>

                    <p>{t('Contact_43')}</p>

                    <ul>
                        <li>{t('Contact_44')} <Link to="mailto:support@loamoa.com">support@loamoa.com</Link> {t('Contact_44_1')}</li>
                    </ul>

                    <p>{t('Contact_45')}</p>

                </div>
            </div>
        </section>
    </>
  )
}

export default TermsofUseContent
