import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Partner = () => {
    const { t } = useTranslation();

    return (
        <section id="partnerSection" className='section feat-partner-sec'>
            <div className="container px-4">
                <div className="row align-items-center">
                    <div className="col-md-8 feat-partner-sec__left">
                        <h2 className='text-dark'>{t('Partners')} <small className='master-tag'>{t('Master')}</small></h2>
                        <p>{t('loamoaPartners')}</p>
                        <p>{t('loamoapcertification')}</p>
                        <p>{t('valuableresource')}</p>
                        <Link className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#partnersProgramModal">{t('View More')}</Link>
                    </div>
                    <div className='col-md-4 feat-partner-sec__right'>
                        <img src="images/partner-fig.png" alt="fig" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Partner
