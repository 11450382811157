import { useEffect } from 'react';
import CareersCard from '../components/CareersCard';

const Careers = () => {

    useEffect(() => {
        document.title = 'LoaMoa || Careers';
        window.scrollTo(0, 0);
    }, []);

    const careersData = [
            {
            creerTitle: 'Business Marketing Manager',
            location: ["San Francisco,USA", "New York, USA"],
            jobs: [
                "5+ years of B2B or B2C marketing experience developing strategy, positioning and messaging for a brand or product in a marketing organisation",
                "3+ years experience with digital media, using advertising data to craft media strategy"
            ]
        },
        {
            creerTitle: 'Head of UK Market Development',
            location: ["London, UK"],
            jobs: [
                "BA or BS degree",
                "5+ years experience focused on driving user growth in matrixed organisations and / or startups",
                "5+ years work experience based in London, with a deep understanding of the consumer, the local market, the local tech and AR communication / content ecosystem scene",
                "Track record of leadership and strong progression, both individually and building teams",
                "Ability to work effectively with cross-functional teams and at all levels of the organization",
                "Full professional fluency in English"
            ]
        },
        {
            creerTitle: 'Head of Japan Market Development',
            location: ["Tokyo, Japan"],
            jobs: [
                "BA or BS degree",
                "5+ years experience focused on driving user growth in matrixed organisations and / or startups",
                "5+ years work experience based in London, with a deep understanding of the consumer, the local market, the local tech and AR communication / content ecosystem scene",
                "Track record of leadership and strong progression, both individually and building teams",
                "Ability to work effectively with cross-functional teams and at all levels of the organization",
                "Full professional fluency in English"
            ]
        }
    ]

    return (
        <>
            <section className='content-page-banner smaller'>
                <div className="container">
                    <h2>Careers</h2>
                </div>
            </section>


            <section className='mission-sec'>
                <div className="container px-4">
                    <div className="col-md-10 offset-md-1">
                        {careersData.map((items, i) => {
                            return <CareersCard key={i} items={items} />
                        })}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Careers;
