import React,{useEffect} from 'react';
import { useTranslation, initReactI18next,} from "react-i18next";
import { useLocation } from 'react-router-dom';
import i18n from 'i18next';
import tEn from '../LanguageTranslate/EN/Translation.json';
import tKo from '../LanguageTranslate/KO/Translation.json';


i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: tEn
            },
            ko: {
                translation: tKo
            },
        },
        lng: "en",
        fallbackLng: "en",

        interpolation: {
            escapeValue: false
        }
    });

const Langi18next = () => {
    const { i18n } = useTranslation();
    const location = useLocation();
    var c = location.search
    var d= c.slice(1)
    if((d!=='')){
        localStorage.setItem('lang',d)
       }
    const changeLang = (e)=>{
        let a =e.target.value
        i18n.changeLanguage(a);
       localStorage.setItem('lang',a);
      
      }
    
      useEffect(() => {
        let currentLang = localStorage.getItem('lang');
         i18n.changeLanguage(currentLang);
        
      }, [i18n])

    return (
        <>
        <select className='select-lang' id="mySelect" value={localStorage.getItem('lang')} onChange={changeLang}>
            <option value="en">English</option>
            <option value="ko">한국어</option>
        </select>
        </>
        
    )
}

export default Langi18next;
