import { useEffect } from 'react';
import FeaturesCard from '../components/FeaturesCard';
import Partner from '../components/Partner';
import featuresData from '../components/featuresData';
import { useTranslation } from 'react-i18next';
import featuresDatako from '../components/featuresDataKo'


const Features = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.title = 'LoaMoa || Features';
        // window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section id='featuresPage' className='features-banner'>
                <div className="container px-4">
                    <div className="row">
                        <div className="col-md-5 offset-md-7">
                            <div className='features-banner__text'>
                                <p>
                                    <span>{t('augmented1')}</span>
                                    <strong className='size36'>
                                        {t('augmented2')}
                                    </strong>
                                    {t('augmented3')}
                                    {/* {t('service that')} */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section treasure-section'>
                <div className="container px-4">
                    <div className='section-header'>
                        <h5 className='font-bold text-primary'>{t('TREASURE')}</h5>
                        <h2 className='text-dark'>{t('Collect the goodies')}</h2>
                    </div>
                    <div className="features-cards-row-content">

                    {
                                localStorage.getItem('lang') === 'ko' ? featuresDatako.map((items, i) => {
                                    return <FeaturesCard key={i} items={items} />
                                }) : featuresData.map((items, i) => {
                                    return <FeaturesCard key={i} items={items} />
                                })
                               }
                    </div>
                </div>
            </section>


            <section className='section chat-section'>
                <div className="container px-4">
                    <div className='col-lg-10 offset-lg-1'>
                        <div className="row chat-section">
                            <div className="col-md-5">
                                <img src="images/chat-fig.png" alt="Chat" />
                            </div>
                            <div className="col-md-7">
                                <h2>{t('Chat')}</h2>
                                <h3 className='size24'>{t('A new')}</h3>
                                <ul>
                                    <li>
                                        <h5>1. {t('General chat')}:</h5>
                                        <p>{t('Similar to')}</p>
                                    </li>
                                    <li>
                                        <h5>2. {t('Proximity chat')}</h5>
                                        <p>{t('Between nearby')}</p>
                                    </li>
                                    <li>
                                        <h5>3. {t('Live chat')}</h5>
                                        <p>{t('Between users')}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='features-msg-sec'>
                <div className="container px-4">
                    <div className='col-lg-10 offset-lg-1'>
                        <div className="row">
                            <div className="col-md-7 features-msg-sec__left">
                                <h2>{t('Messages')}</h2>
                                <h3>{t('Stay connected')}</h3>
                                <p>{t('LoaMoa chat lets')}</p>
                            </div>
                            <div className="col-md-4 offset-md-1 features-msg-sec__right">
                                <img src="images/feature-message.png" alt="fig" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='features-flash-sec'>
                <div className="container px-4">
                    <div className="row">
                        <div className="col-md-6 features-flash-sec__left">
                            <img src="images/dome-flash-mobile.png" alt="Mobile" />
                        </div>
                        <div className="col-md-6 features-flash-sec__right">
                            <h2>{t('flash')} <small className='master-tag'>{t('Master')}</small></h2>
                            <h4>{t('useloamoa')}</h4>
                            <p>{t('flashSolution')}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='section feature-adskit-sec'>
                <div className="container py-4">
                    <div className='feature-adskit-sec__inside'>
                        <div className="row align-items-center">
                            <div className="col-lg-6 feature-adskit-sec__inside__left">
                                <h2>{t('Adskit')} <small className='master-tag'>{t('Master')}</small></h2>
                                <p>{t('joinloamoa')}</p>
                            </div>
                            <div className="col-lg-5 offset-lg-1 feature-adskit-sec__inside__right">
                                <img className='feature-adskit-men' src="images/feature-adskit-men.png" alt="fig" />
                                <img className='feature-adskit-mobile' src="images/feature-adskit-mobile.png" alt="fig" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='feat-green-sec'>
                <div className="container px-4">
                    <div className="row">
                        <div className="col-md-6 feat-green-sec__left">
                            <img className='green-mobile' src="images/green-mobile.png" alt="fig" />
                            <img className='green-girl-fig' src="images/green-girl-fig.png" alt="fig" />
                        </div>
                        <div className="col-md-6 feat-green-sec__right">
                            <h2>{t('Green')} <small className='master-tag'>{t('Master')}</small></h2>
                            <p>{t('everyoneHas')}</p>
                            <p>{t('loamoaEffective')}</p>
                        </div>
                    </div>
                </div>
            </section>

            <Partner />


        </>
    )
}

export default Features
