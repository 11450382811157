import Slider from "react-slick";
import { useTranslation } from 'react-i18next';
import SocialComponent from './SocialComponent';

export default function HeroBanner() {
    const { t } = useTranslation();
    var heroBannerSettings = {
        arrows: false,
        fade: true,
        dots: true,
        autoplay: true,
        speed: 2000
    };

    return (
        <section className="hero-banner">
            <Slider className="heroSlider" {...heroBannerSettings}>
                <div className="heroSlider__slide slide_1">
                    <div className="container px-4">
                        <div className="heroSlider__slide__text">
                            {/* <h2>Have you heard of <strong>LoaMoa</strong> Flash yet?</h2> */}
                             <h2>{t('heard')} </h2> 
                            <h5>{t('Flash')} </h5>
                            <p> {t('gifting')}</p>
                            <h6>{t('Download App')}</h6>
                            <SocialComponent />
                        </div>
                    </div>
                    <div className="slide-1-vector">
                        <img src="images/slider-1-vector.png" alt="vector" />
                    </div>
                </div>
                <div className="heroSlider__slide slide_2">
                    <div className="container px-4">
                        <div className="heroSlider__slide__text">
                            {/* <h2>Flash, which <strong>decorates big events</strong> such as professional <strong>baseball</strong> and <strong>soccer</strong> even more beautifully!</h2> */}
                              <h2>{t('decorates')}</h2> 
                            <p>{t('gifting')}</p>
                            <h6>{t("Download App")}</h6>
                            <SocialComponent />
                        </div>
                    </div>
                    <div className="flash-vetor">
                        <img src="images/flash-vetor.png" alt="vector" />
                    </div>
                </div>
                <div className="heroSlider__slide slide_3">
                    <div className="container px-4">
                        <div className="heroSlider__slide__text">
                            {/* <h2>Let's Hold a surprise gift-giving event for our precious <strong>family and friends with flash!</strong></h2> */}
                            <h2>{t('surprise')}</h2>
                            <p>{t('gifting')}</p>
                            <h6>{t('Download App')}</h6>
                            <SocialComponent />
                        </div>
                    </div>
                    <div className="flash-vetor">
                        <img src="images/flash-vetor.png" alt="vector" />
                    </div>
                </div>
            </Slider>
        </section>
    )
}
