const featuresDatako = [
    {
        title: "플래시",
        description: "플래시 아이콘을 탭하여 이벤트에서 제공되는 훌륭한 상품과 현금 포인트를 획득하세요.",
        icon: "flash-icon.svg"
    },
    {
        title: "캔디",
        description: "캔디 아이콘을 탭하여 세계의 최고 점수 보유자가 되고 혜택도 누려보세요.",
        icon: "candy-icon.svg"
    },
    {
        title: "포인트",
        description: "별 아이콘을 탭하여 현금 포인트를 획득하세요.",
        icon: "points-icon.svg"
    },
    {
        title: "이모티콘",
        description: "이모티콘 아이콘을 탭하여 좋아하는 LoaMoa 캐릭터 이모티콘을 수집하고 소유하세요.",
        icon: "stickers-icon.svg"
    },
    {
        title: "그린",
        description: "그린 아이콘을 탭하여 환경 보호 캠페인에 기부하고 뱃지를 획득하세요.",
        icon: "green-icon.svg"
    }
]

export default featuresDatako;