import { Link } from 'react-router-dom';

const SocialComponent = () => {
    let localLang = localStorage.getItem('lang');
    let androidUrl = `https://play.google.com/store/apps/details?id=com.loamoa&hl=${localLang}_BO&pli=1`
    
  return (
    <div className='socialComponent'>
        <Link to="https://apps.apple.com/tc/app/loamoa/id1500906138"><img src="images/app-store-btn-long.svg" alt="App Store" /></Link>
        <Link to={androidUrl}><img src="images/play-store-btn-long.svg" alt="Play Store" /></Link>
    </div>
  )
}

export default SocialComponent
